import React, { useState } from "react";
import "./styles.less";
import { Carousel } from "antd";
import { useDeviceDetect } from "../../utils/hooks";

const ScrollViewer = (props) => {
  const [current, setCurrent] = useState(0);
  const { isMobile } = useDeviceDetect();

  if (isMobile) {
    return (
      <div className="scroll-viewer-wrapper">
        <div className="carousel-viewer">
          <Carousel afterChange={(i) => setCurrent(i)} dots={false}>
            {props.elements.map((image, i) => {
              return (
                <div key={`element-${i}`} className="element-wrapper">
                  {image.file.contentType.includes("video") ? (
                    <video
                      autoPlay={false}
                      controls
                      loop
                      poster={props.posterUrl}
                      src={image.file.url}
                    />
                  ) : (
                    <img src={image.file.url} />
                  )}
                </div>
              );
            })}
          </Carousel>
        </div>
        <div className="status-mobile">
          <div className="slide-status">
            {current + 1}/{props.elements?.length}
          </div>
          <h1>{props.title}</h1>
          <p>{props.subtitle}</p>
        </div>
      </div>
    );
  } else {
    return (
      <>
        {/* show videos */}
        {
          <div className="video-gallery-wrapper">
            <div className="video-gallery">
              {props.elements
                // filter out non-video files
                .filter((element) => element.file.contentType.includes("video"))
                .map((video, i) => {
                  return (
                    <div key={`video-${i}`} className="video-wrapper">
                      <video autoPlay loop>
                        <source
                          src={video.file.url}
                          type={video.file.contentType}
                        />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  );
                })}
            </div>
          </div>
        }
        {/* show photos */}
        <div className="scroll-viewer-wrapper">
          <div className="simple-title">
            <h1>{props.title}</h1>
            <p>{props.subtitle}</p>
          </div>

          <div className="scroll-viewer">
            {props.elements
              // filter out non-image files
              .filter((element) => element.file.contentType.includes("image"))
              .map((image, i) => {
                return (
                  <div
                    key={`col-${i}`}
                    className={`background ${isMobile ? "mobile" : "parallax"}`}
                    style={{
                      backgroundImage: `url(${image.file.url})`,
                      opacity: props.isLoading ? 0 : 1,
                    }}
                    data-img-width="auto"
                    data-img-height="1064"
                    data-diff="0"
                  />
                );
              })}

            {props.footer}
          </div>
        </div>
      </>
    );
  }
};

export default ScrollViewer;

import React, { useState, useEffect } from "react";
import { graphql, withPrefix } from "gatsby";
import Helmet from "react-helmet";
import config from "../utils/siteConfig";
import Layout from "../components/Layout/Layout";
import ScrollViewer from "../components/ScrollViewer";
import PageFooter from "../components/Layout/PageFooter";
import { useDeviceDetect } from "../utils/hooks";

const PhotosTemplate = ({ data, location, pageContext }) => {
  const { title, elements, subtitle, poster } = data.contentfulCollection;
  const { isMobile } = useDeviceDetect();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = withPrefix("/js/parallax.js");
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <Layout
      showLogo={isMobile}
      logoClass={isMobile ? "small" : ""}
      location={location}
    >
      <Helmet>
        <title>{`${title} - ${config.siteTitle}`}</title>
      </Helmet>

      <ScrollViewer
        posterUrl={poster?.file?.url}
        title={title}
        isLoading={false}
        subtitle={subtitle}
        elements={elements}
        footer={<PageFooter />}
      />
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    contentfulCollection(id: { eq: $id }) {
      title
      subtitle
      poster {
        file {
          url
        }
      }
      elements {
        file {
          url
          # check file type
          contentType
        }
      }
    }
  }
`;

export default PhotosTemplate;
